import React, { FunctionComponent, useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react'
import {
    Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Heading, Input, Select, useMultiStyleConfig, Checkbox,
    VStack, useToast, useDisclosure, Link
} from '@chakra-ui/react';
import SellBanner from 'src/components/Sell/Banner';
import { useAppSelector } from 'src/redux/hooks';
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,

} from 'formik';
import * as Yup from "yup";
import { fetchDealersByCity } from 'src/redux/reducers/dealerReducer';
import { useAppDispatch } from 'src/redux/hooks';
import { fetchModelList } from 'src/redux/reducers/modelListReducer';
import saveLead from 'src/api/saveLead';
import { DocumentsReq } from 'src/components/Sell/DocumentsReq';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { updateMakeValue } from 'src/redux/reducers/commonReducer';
import gtag_report_conversion from 'src/getGtagReport';
import {eventCallingGA} from 'src/lib/ga';
import {eventCallingGAdWords} from 'src/lib/gaAdWords';
import {eventMeta} from 'src/lib/meta';

const styles = {
    title: {
        textAlign: { xl: 'center' },
        color: "gray",
        fontWeight: "medium",
        fontSize: { base: 'lg', xl: '3xl' },
        mb: { base: 4, xl: 6 },
        lineHeight: "tall",
        position: 'relative',
    },
    titleBorder: {
        display: { base: 'block', xl: 'none' },
        w: 36,
        borderBottom: '2px solid gray',
        pt: 2
    },
    text: {
        fontSize: { base: 'sm', xl: 'xl' },
        color: 'gray4',
        textAlign: { xl: 'center' },
        lineHeight: "tall",
        mb: 10
    },
    sectionBg: {
        bg: 'lightgray',
        pt: { base: 5, xl: 10 },
        pb: { base: 5, xl: 8 }
    },
    subTitle: {
        fontSize: { base: 'base', xl: 'black' },
        fontWeight: 'medium',
        mb: 6
    },
    formGrid: {
        gridTemplateColumns: { xl: 'repeat(2,1fr)' },
        rowGap: { base: 10, xl: 8 },
        columnGap: { xl: 16 },
        mb: { base: 10, xl: 15 }
    },
    formControl: {
        mb: 5,
        _last: {
            mb: 0
        }
    },
    // text-xs text-gray4 leading-snug
    notes: {
        fontSize: 'xs',
        color: 'gray4',
    }
}

const SellPage: FunctionComponent = () => {
    const containerStyle = useMultiStyleConfig(`Container`);
    const dealerId = useAppSelector((state: any) => state.cities.dealer);
    const defaultcity = useAppSelector((state: any) => state.cities.cityId)
    const dispatch = useAppDispatch();
    const toast = useToast();
    const ref = useRef<HTMLDivElement>(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const makeList = useAppSelector((state: any) => state.makes.data);
    const makeListStatus = useAppSelector((state: any) => state.makes.status)
    const yearList = useAppSelector((state: any) => state.yearList.data);
    const cityList = useAppSelector((state: any) => state.cities.data);
    const dealerList = useAppSelector((state: any) => state.dealer.listByCity.data);
    const modelList = useAppSelector((state: any) => state.modelList.data);
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);

    const [firstName, setFirstName] = useState<string>("");
    const [lastname, setLastName] = useState<string>("");
    const [invalidFistName, setInvalidFirstName] = useState<boolean>(false);
    const [invalidLastName, setInvalidLastName] = useState<boolean>(false);
    const [mobileNo, setMobileNo] = useState<string>("");
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [regNo, setRegNo] = useState<any>();
    const [make, setMake] = useState<string>("");
    const [model, setModel] = useState<string>("");
    const [city, setCity] = useState<any>();
    const [dealer, setDealer] = useState<any>();
    const [invalidCity, setInvalidCity] = useState<boolean>(false);
    const [invalidYear, setInvalidYear] = useState<boolean>(false);
    const [invalidRegNo, setInvalidRegNo] = useState<boolean>(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);
    const [resetForm, setResetForm] = useState<any>(false);
    const [resetMake, setResetMake] = useState<any>(false);
    const [resetModel, setResetModel] = useState<any>(false);



    const location = useLocation();
    const navigate = useNavigate();
    let makeValue = "";
    let modelValue = "";

    if (location?.state && typeof location.state === 'object' && 'make' in location.state && 'model' in location.state) {
        makeValue = (location.state as { make: string }).make;
        modelValue = (location.state as { model: string }).model;
        //     navigate('', { replace: true });

    }
    const makevalue = useAppSelector((state: any) => state.commonData.makeValue);


    const styless = {
        links: {
            _hover: {
                color: "primary",
            }
        }
    }
    const decumentsProps: any = {
        isOpen,
        onClose,
    }

    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        const mobileRegex = /^[1-9]\d{9}$/; // Regular expression for Indian mobile numbers
        const isValidMobile = mobileRegex.test(e.target.value);
        setInvalidMobile(!isValidMobile);
        setMobileNo(e.target.value);
    }
    const validateVechileNo = (e: ChangeEvent<HTMLInputElement>) => {
        setRegNo(e.target.value);
    }
    const handleReset = () => {
        setResetForm(!resetForm);
        setRegNo("");
        setFirstName("");
        setLastName("");
        setMobileNo("");
        setEmail("");
        dispatch(updateMakeValue(""));    
    }
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
 
        setSubmitBtnLoading(true)
        if (firstName.length === 0) {
            setInvalidFirstName(true)
        }

        const params = {
            first_name: firstName,
            last_name: lastname,
            cus_mobile: mobileNo,
            cus_email: email,
            city: city,
            make: make,
            model: model,
            year: year,
            dealer_id: dealer,
            reg_no: regNo,
            terms_conditions: true,
            is_subdomain: includesSubDomain ? true : false,
            umt_tracking_id : Cookies.get('utmTrackID')
        }

        const res = saveLead(params);
        res.then((data: any) => {
            if (data.status === "success") {
                if (typeof window.gtag === 'function') {
                    window.gtag('event', 'conversion', {
                        'allow_custom_scripts': true,
                        'send_to': 'DC-9933106/audia00/audia0+standard'
                    });
                    console.log('Google Ads conversion event triggered SELL');
                } else {
                    console.error('gtag function not available SELL');
                }
                // olx tracking
                const img = document.createElement('img');
                img.src = 'https://olx.gotrackier.com/pixel?adid=670cdbbbdd73a17ca05d8838';
                img.alt = 'Tracking Pixel';
                img.style.display = 'none';
                document.body.appendChild(img);
                // olx track ending
                if (process.env.REACT_APP_ENV=="prod")
                {
                    // eventCallingGA("DC-9933106/audia0/audia000+standard");
                    // console.log(window.dataLayer,"calling GA event");
                    // eventCallingGAdWords("AW-923778505/z-aZCOnp624Qyfu-uAM");
                    // console.log(window.dataLayer,"calling ga ad words event");
                    // eventMeta("389066024760021","Lead");
                    // console.log(window.dataLayer,"calling ga ad words event");
                //(new Image).src="https://ventes40.gotrackier.com/pixel?adid=63a585fc4204fa0dc972de06&txn_id="+data.lead_id;
                //  gtag_report_conversion("https://www.audiapprovedplus.in/sell");
                //  gtag('event', 'conversion', {'send_to': 'AW-923778505/z-aZCOnp624Qyfu-uAM'});
                //  if (window.location.hostname.includes('noida.audiapprovedplus')) {
                //     fbq('init', '307433628486132');
                //     fbq('track', 'PageView');
                //     fbq('track', 'Lead');
                // }
                }
                toast({
                    title: data.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                setCity("");
                setYear("");
                setDealer("");
                setMake("");
                setModel("");
                setRegNo("");
                handleReset();


            } else {
                toast({
                    title: data.message.replace(/<[^>]+>/g, ' '),
                    status: 'warning',
                    isClosable: true,
                    position: 'bottom-right'
                })
            }
            setSubmitBtnLoading(false);
        })
        
        
    }


    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
        let customer = Cookies.get('cust');
        if (customer) {
          const parsedCustomer = JSON.parse(customer);
          if (typeof parsedCustomer === 'object' && parsedCustomer !== null) {
            setFirstName(parsedCustomer.first_name);
            setLastName(parsedCustomer.last_name);
            setMobileNo(parsedCustomer.mobile);
            setEmail(parsedCustomer.email);
          }
        }
        // if (includesSubDomain && defaultCity) {
        if (defaultcity) {
            setCity(defaultcity);
        }
       // if (includesSubDomain && dealerId) {
        if(dealerId) {
            setDealer(dealerId);
        }
    }, []);

    useEffect(() => {
        if (city) {
            dispatch(fetchDealersByCity(city));
        }

    }, [city]);

    useEffect(() => {
        if (make) {
            dispatch(fetchModelList(make));
        }
    }, [make]);
    useEffect(() => {
        if (makevalue) {
            setMake(makevalue)
        }
    }, [makevalue]);

    useEffect(() => {
        if (modelValue) {
            setModel(modelValue);
        }
    }, [modelValue]);
    


    const handleResetMake = () => {
        setResetMake(!resetMake);
    }
    const handleResetModel = () => {
        setResetModel(!resetModel);
    }
    const changeYear = (e: any) => {

        const newValue = e.target.value;
        setYear(newValue);
        // Check if make is already selected and reset it if year value changes to "reset"
        if (make !== '' || model !== '') {
            handleResetMake();
            handleResetModel();
            setMake('');
            setModel('');
        }
    }


    return (
        <>
            <SellBanner />
            <Box sx={containerStyle.wrapper} ref={ref}>
                <Heading as="h1" sx={styles.title}>
                    Schedule an appointment
                    <Box as='span' sx={styles.titleBorder} />
                </Heading>
                <Box as='p' sx={styles.text}>Our representative will meet you at a location and time that is convenient to you.</Box>
            </Box>
            <Box sx={styles.sectionBg}>
                <Box sx={containerStyle.wrapper}>
                    <form onSubmit={handleSubmit} id="myForm" key={resetForm}>
                        <Grid sx={styles.formGrid}>
                            <Box order={{ xl: 1 }}>

                                <Box as="h3" sx={styles.subTitle}>Personal details</Box>
                                {/* <Grid sx={styles.formGrid}> */}
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={invalidFistName}>
                                    <Input name='firstName' id="firstName" type='text' placeholder=" " value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
                                    <FormLabel htmlFor='firstName'>First Name</FormLabel>
                                    <FormErrorMessage>Please add valid first name</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={invalidLastName}>
                                    <Input name='lastName' type='text' placeholder=" " value={lastname} onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
                                    <FormLabel htmlFor='lastName'>Last Name</FormLabel>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={invalidMobile}>
                                    <Input name='mobile' type='tel' placeholder=" "  maxLength={10}  value={mobileNo} onChange={validateMobileNo} />
                                    <FormLabel htmlFor='mobile'>Mobile</FormLabel>
                                    <FormErrorMessage>Invalid mobile number</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={false}>
                                    <Input name='email' type='email' placeholder=" " value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                                    <FormLabel htmlFor='email'>Email</FormLabel>
                                </FormControl>
                                {/* </Grid> */}
                            </Box>
                            {/* <Box order={{ xl: 3 }}>
                                
                            </Box> */}
                            <Box order={{ xl: 2 }}>
                                <Box as="h3" sx={styles.subTitle}>Vehicle details</Box>
                                <FormControl sx={styles.formControl} variant='floating' isInvalid={invalidYear}>
                                    <Select placeholder='Year' onChange={changeYear} variant='mySelect'>
                                        {
                                            yearList.length !== 0 ? yearList.map((item: any, i: any) => (<option key={i} value={item}>{item}</option>)) : <option>Loading</option>
                                        }
                                    </Select>
                                    <FormErrorMessage>Select any year</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                    <Select key={resetMake} placeholder='Make' onChange={(e: ChangeEvent<HTMLSelectElement>) => setMake(e.target.value)} variant='mySelect' >
                                        {
                                            (makeListStatus === "success" && makeList.length !== 0) ? makeList.map((item: any, index: number) => (<option key={index} value={item}
                                                selected={item === makevalue}>{item}</option>)) : (<option>Loading</option>)
                                        }
                                    </Select>
                                    <FormErrorMessage>Select any Make</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                    <Select key={resetModel} placeholder='Model' onChange={(e: ChangeEvent<HTMLSelectElement>) => setModel(e.target.value)} defaultValue={"Model Name"} variant='mySelect' isDisabled={make ? false : true}>
                                        {
                                            (modelList.length !== 0) ? modelList.map((item: any, index: number) => (<option key={index} value={item}
                                                selected={item === modelValue}>{item}</option>)) : (<option>Loading</option>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant='floating' isInvalid={false}>
                                    <Input name='regNo' type='text' placeholder=" " value={regNo} onChange={validateVechileNo} max={10} />
                                    <FormLabel htmlFor='regNo'>Enter registration number</FormLabel>
                                    <FormErrorMessage>Please add valid Vehicle registation number</FormErrorMessage>
                                </FormControl>

                                <Box as="h3" sx={styles.subTitle}>Select dealer</Box>
                                {includesSubDomain ?
                                    <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={invalidCity}>
                                        <Select placeholder='Select City' value={defaultcity} variant='mySelect'>
                                            {
                                                cityList.length !== 0 ? cityList.filter((item: any) => item.city_id == defaultcity).map((item: any) => (<option
                                                    selected={item.city_id === defaultcity} key={item.city_id} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                                            }
                                        </Select>
                                        <FormErrorMessage>Select any city</FormErrorMessage>
                                    </FormControl>
                                    :
                                    <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={invalidCity}>
                                        <Select placeholder='Select City' onChange={(e: ChangeEvent<HTMLSelectElement>) => setCity(e.target.value)} variant='mySelect'>
                                            {
                                                cityList.length !== 0 ? cityList.map((item: any) => (<option selected={item.city_id == defaultcity} key={item.city_id} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                                            }
                                        </Select>
                                        <FormErrorMessage>Select any city</FormErrorMessage>
                                    </FormControl>
                                }
                                {includesSubDomain ?
                                    <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                        <Select placeholder='Select Dealer' onChange={(e: ChangeEvent<HTMLSelectElement>) => setDealer(e.target.value)} variant='mySelect'>
                                            {
                                                dealerList.length !== 0 ? dealerList.filter((item: any) => item.id === dealerId).map((item: any) => (<option
                                                    selected={item.id === dealerId}
                                                    key={item.id} value={item.id}>{item.name}</option>)) : <option>Loading</option>
                                            }
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                        <Select placeholder='Select Dealer' onChange={(e: ChangeEvent<HTMLSelectElement>) => setDealer(e.target.value)} variant='mySelect' isDisabled={city ? false : true}>
                                            {
                                                dealerList.length !== 0 && city ? dealerList.map((item: any) => (<option key={item.id}  selected={item.id == dealerId} value={item.id}>{item.name}</option>)) : <option>Loading</option>
                                            }
                                        </Select>
                                    </FormControl>
                                }
                            </Box>
                            <Box order={{ xl: 4 }} alignSelf="flex-end">
                                <Flex gap={{ base: 4, xl: 1 }} flexDirection={{ base: 'column', xl: 'row' }} mb={5}>
                                    <Button type='submit' variant="primary"  disabled={submitBtnLoading} size={'lg'} w="full" minW={60}>Submit</Button>
                                    <Button type='reset' variant="primary" size={'lg'} w="full" minW={60} onClick={handleReset}>Reset</Button>
                                </Flex>
                                <Box as='p' sx={styles.notes}>
                                    By clicking submit, you agree to the Terms &amp; Condition
                                    <Link as={RouterLink}
                                        to={`/tnc`}
                                        target="_blank"
                                        sx={styless.links}> (click here)</Link>
                                </Box>
                            </Box>
                        </Grid>
                    </form>

                    <Flex justifyContent="flex-end">
                        <Button variant="link" onClick={onOpen}>Check documents required</Button>
                    </Flex>
                </Box>
            </Box>
            <DocumentsReq {...decumentsProps} />
        </>
    );
}

export default SellPage;