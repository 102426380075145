import { FunctionComponent, useEffect, useState } from 'react';
import { Outlet,useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from './components/Footer';
import CityModal from './components/CityModal';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { fetchStateList } from './redux/reducers/stateReducer';
import { fetchCityList, updateDealer } from './redux/reducers/cityListReducer';
import { fetchToken } from './redux/reducers/ibbTokenReducer';
import { fetchMakeList } from './redux/reducers/makeListReducer';
import { fetchYearList } from './redux/reducers/yearListReducer';
import { Flex, ChakraProvider, Image } from '@chakra-ui/react';
import { CustomerModal } from './components/CustomerModal';
import { fetchPiceList } from './redux/reducers/priceListReducer';
import { fetchKmList } from './redux/reducers/kmListReducer';
import { fetchConstants } from './redux/reducers/constantReducer';
import { updateMaxLimit, updateSubdomain } from './redux/reducers/commonReducer';
import { CaptchaModal } from './components/captchaModal';
import { fetchSubdomainList } from './redux/reducers/subdomainReducer';
import { openCustomerModal } from 'src/redux/reducers/CustomerFormReducer';
import { updateCityId, updateCity } from 'src/redux/reducers/cityListReducer';
import { openCityModal } from './redux/reducers/cityListReducer';
import Cookies from 'js-cookie';
import getSubDomain from './api/getSubDomain';
import {initializeGA} from 'src/lib/ga';
import {initializeGTag} from 'src/lib/gtag';
import {initializeGAdWords} from 'src/lib/gaAdWords';
import {initializeMeta} from 'src/lib/meta';
const googleAnalyticID = process.env.GA_ID || "DC-9933106";
const googleAnalyticAWID = process.env.GA_AW_ID || "AW-923778505";

const App: FunctionComponent = () => {
  const queryString = window.location.search;
  // Parse query parameters
  const params = new URLSearchParams(queryString);
  const utmTrackID = params.get('utm_Tracking-id');
  const utmTrackIDCookie = Cookies.get("utmTrackID");
  if(utmTrackID && !utmTrackIDCookie){
    Cookies.set('utmTrackID', utmTrackID, { expires: 15 / (24 * 60) }); // 15 / (24 * 60) 15 minute
  }
  const [theme, setTheme] = useState({});
  const location = useLocation();
  const dispatch = useAppDispatch()
  const ibbToken = useAppSelector((state: any) => state.ibbToken);
  const subDomain = useAppSelector((state: any) => state.subDomain.data);
  //const subDomainStatus = useAppSelector((state: any) => state.subDomain.status);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [subdomain, setSubdomain] = useState<any>(false);
  const [subDomainStatus, setSubdomainStaus] = useState<string>("");
  const cookieValue = Cookies.get('cust');
  const showHeaderAndFooter =
  location.pathname !== '/valuation_app_download' && location.pathname !== '/sales_consultant_app_download';
  
  const fetchTheme = async () => {
    const res = await import(`./theme/${process.env.REACT_APP_THEME}/index`)
      .then((module) => {
        setTheme(module.default)
      })
      .catch((err) => {
        console.log("Failed to load moment", err);
      });
    return res
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    if (width <= 768) {
      dispatch(updateMaxLimit(2));
    }
    else {
      dispatch(updateMaxLimit(3));
    }
  }
  const pagelocation = useLocation();

    // useEffect(() => {
    //     // initializeGTag();
    //     // Send pageview event to Google Analytics on initial load
    //     if(process.env.REACT_APP_ENV=="prod"){
    //       initializeGA(googleAnalyticID);
    //       initializeGAdWords(googleAnalyticAWID);
    //       initializeMeta();
    //     }
    //     console.log("===>",process.env);
    // }, [pagelocation.key]);
    const googleAnalyticID = process.env.GA_ID || "DC-9933106";
    const googleAnalyticAWID = process.env.GA_AW_ID || "AW-923778505";

    useEffect(() => {
      if (process.env.REACT_APP_ENV === "prod" && true) {
        // Initialize Google services
        // initializeGA(googleAnalyticID);
        // initializeGTag(googleAnalyticID);
        initializeGAdWords(googleAnalyticAWID);
        initializeMeta();
    
        // Track page view on route change
        const pagePath = pagelocation.pathname + pagelocation.search;
        if (window.gtag) {
          window.gtag('config', googleAnalyticID, {
            page_path: pagePath,
          });
        }
      }
    }, [pagelocation.pathname, pagelocation.search]);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    (async () => {
      fetchTheme()
      let isExpired = new Date() > new Date(ibbToken?.expires)
      if (ibbToken?.token == null || isExpired) {
        await dispatch(fetchToken());
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
    if(ibbToken?.token)
    {
    await dispatch(fetchStateList());
    await dispatch(fetchCityList());
    await dispatch(fetchSubdomainList());
    await dispatch(fetchYearList());
    await dispatch(fetchPiceList());
    await dispatch(fetchKmList());
    await dispatch(fetchConstants());
    await dispatch(fetchMakeList(ibbToken?.token));
  }})();
  }, [ibbToken])



  useEffect(() => {
    (async () => {
      if (ibbToken) {
        let host = window.location.host;
        const res = await getSubDomain();
        const matchingSubdomain = res?.find((item: any) => host.includes(item.url));
        if (matchingSubdomain) {
          setSubdomain(true);
          dispatch(updateSubdomain(true));
          dispatch(openCustomerModal(true));
          dispatch(updateCityId(matchingSubdomain.city_id));
          dispatch(updateCity(matchingSubdomain.city_name));
          dispatch(updateDealer(matchingSubdomain.dealer_id));
          setSubdomainStaus("succeeded");
        }
        else {
          setSubdomain(false);
          dispatch(updateSubdomain(false));
          setSubdomainStaus("succeeded");
        }
      }
    })();
  }, [ibbToken?.token]);

  if (Object.keys(theme).length === 0) {
    return (
      <Flex alignItems={'center'} justifyContent="center" w="100%" h="100%" position="fixed" left="0" top="0">
        <Image
          src={`/images/${process.env.REACT_APP_THEME}/${process.env.REACT_APP_LOGO_FILE}`}
          alt={`${process.env.REACT_APP_NAME}`}
          w="180px"
          h='50px'
          objectFit="contain"
          objectPosition="center center"
        />
      </Flex>
    )
  }

  return (
    <ChakraProvider resetCSS theme={theme}>
    {showHeaderAndFooter &&    <Header />}
      <main>
        <Outlet />
      </main>
     {showHeaderAndFooter &&    <Footer />}  
      {/* <CaptchaModal /> */}
      {!subdomain && subDomainStatus === 'succeeded' &&  showHeaderAndFooter && <CityModal />}
      {!cookieValue && showHeaderAndFooter &&  <CustomerModal />}
    </ChakraProvider>
  )
}

export default App