import ibbApi from "src/api/ibbApi";
import { SAVE_LEAD } from "src/constants"

interface IPayload {
    first_name: string;
    last_name: string;
    cus_mobile: string;
    cus_email: string;
    city: any;
    make: string;
    model: string;
    year: string;
    dealer_id: string,
    reg_no: string,
    terms_conditions: boolean,
    is_subdomain:boolean,
    umt_tracking_id:string|undefined
}
const saveLead = (params: IPayload): any => {
    const leadParams = {
        first_name: params.first_name,
        last_name: params.last_name,
        cus_mobile: params.cus_mobile,
        cus_email: params.cus_email,
        city: params.city,
        make: params.make,
        model: params.model,
        year: params.year,
        dealer_id: params.dealer_id,
        reg_no: params.reg_no,
        terms_conditions: true,
        platform_id: `${process.env.REACT_APP_PLATFORM_ID}`,
        variant:'',
        is_subdomain:params.is_subdomain,
        umt_tracking_id : params.umt_tracking_id
    };

        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                `${SAVE_LEAD}`,
                leadParams,
                {
                    headers: headers
                }
            )
            .then((response) => {
                return response.data})
            .catch((error) => error)
    return res;
};
export default saveLead
